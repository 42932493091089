<template>
  <div class="useral-edit-user">
    <!--    <div class="menu-side mb-3 sm:mb-0">-->
    <!--      <ul>-->
    <!--        <li class="menu-item"-->
    <!--            @click="tabIndex = 0">-->
    <!--          اطلاعات عمومی-->
    <!--          <vs-icon icon="icon-chevron-left"-->
    <!--                   icon-pack="feather"/>-->
    <!--        </li>-->

    <!--&lt;!&ndash;        <li class="menu-item"&ndash;&gt;-->
    <!--&lt;!&ndash;            v-if="checkUserPermissions('user.set_role') && userId !== $store.state.auth.user.id"&ndash;&gt;-->
    <!--&lt;!&ndash;            @click="tabIndex = 1">&ndash;&gt;-->
    <!--&lt;!&ndash;          سطح دسترسی&ndash;&gt;-->
    <!--&lt;!&ndash;          <vs-icon icon="icon-chevron-left"&ndash;&gt;-->
    <!--&lt;!&ndash;                   icon-pack="feather"/>&ndash;&gt;-->
    <!--&lt;!&ndash;        </li>&ndash;&gt;-->
    <!--      </ul>-->
    <!--    </div>-->


    <vs-divider class="md:hidden"/>

    <div class="view-side mt-3 sm:mt-0">
      <div v-show="tabIndex !== 4"
           class="view-box">
        <vs-row>
          <vs-col>
            <custom-profile-image-input v-model="user.image"/>

            <div class="phone-input">
              <custom-phone-number-input label="شماره همراه"
                                         :classes="{'w-full': true}"
                                         v-model="user.phoneNumber"/>
              <button class="confirm-phone" @click="searchPhoneNumber">استعلام</button>
            </div>


            <div>
              <u-radio-input
                  id="genderRadio"
                  label="جنسیت"
                  :options="gender"
                  v-model="user.gender"
              />

              <custom-validate-input label="نام"
                                     :classes="{'w-full': true}"
                                     align="right"
                                     rtl
                                     invalid-text="نام باید فارسی باشد!"
                                     :disabled="inputsDisabled"
                                     :is-empty="inputsDisabled"
                                     :regex="nameRegex"
                                     v-model="user.name"/>

              <custom-validate-input label="نام خانوادگی"
                                     :classes="{'w-full': true}"
                                     align="right"
                                     rtl
                                     invalid-text="نام خانوادگی باید فارسی باشد!"
                                     :disabled="inputsDisabled"
                                     :is-empty="inputsDisabled"
                                     :regex="nameRegex"
                                     v-model="user.lastName"/>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button v-show="false"
               id="insertManagerBTN"
               @click="sendData"/>
  </div>
</template>

<script>
import axios from 'axios'
import DatePicker from 'vue-persian-datetime-picker'
import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
import CustomeNumberInput from '../../../../../components/customInput/customNumberInput.vue'
import CustomPhoneNumberInput from '../../../../../components/customInput/customPhoneNumberInput'
import CustomEmailInput from '../../../../../components/customInput/customEmailInput'
import CustomMultipleSelect from '../../../../../components/customMultipleSelect/customMultipleSelect'
import CustomValidateTextarea from '../../../../../components/customInput/customValidateTextarea'
import {insertUser, editUser, getUser, getUsers} from '../../../../../http/requests/users/users'
import CustomDialog from '../../../../../components/customDialog/customDialog'
import {getRoles} from '../../../../../http/requests/settings/roles'
import {getProvinces} from '../../../../../http/requests/provinces'
import {checkUserPermissions} from '../../../../../assets/js/functions'
import CustomSelect from '../../../../../components/customSelect/customSelect'
import CustomFileInput from "../../../../../components/customInput/customeFileInput";
import CustomProfileImageInput from "../../../../../components/customInput/customProfileImageInput";
import URadioInput from "@/components/customInput/URadioInput.vue";

export default {
  name: 'InsertUser',
  components: {
    URadioInput,
    DatePicker,
    CustomProfileImageInput,
    CustomFileInput,
    CustomValidateTextarea,
    CustomSelect,
    CustomMultipleSelect,
    CustomDialog,
    CustomEmailInput,
    CustomPhoneNumberInput,
    CustomeNumberInput,
    CustomValidateInput
  },
  props: {
    userId: 0
  },
  data() {
    return {
      status: 'edit',
      inputsDisabled: true,
      tabIndex: 0,
      nameRegex: this.$validator('regex.setting.employee.name'),
      logs: [],
      provinces: [],
      cities: {},
      gender: [
        {
          value: 1,
          label: 'آقا'
        },
        {
          value: 2,
          label: 'خانم'
        }
      ],
      user: {
        id: -1,
        name: {
          value: '',
          isValid: true
        },
        lastName: {
          value: '',
          isValid: true
        },
        fatherName: {
          value: '',
          isValid: true
        },
        nationalCode: {
          value: '',
          isValid: false
        },
        birthCertificateId: {
            value: '',
            isValid: true
        },
        image: {
          value: '',
          isValid: true
        },
        birthDate: {
            value: '',
            isValid: true
        },
        gender: {
          value: -1,
          label: 'آقا'
        },
        sportInsurance: {
          id: 1,
          label: 'دارد'
        },
        phoneNumber: {
          value: '',
          isValid: false
        },
        email: {
          value: '',
          isValid: true
        },
        birthProvince: {
          id: '',
          label: ''
        },
        province: {
          id: '',
          label: ''
        },
        address: {},
        playerScore: {
          value: '',
          isValid: true
        },
        coachDegree: {
          id: ''
        },
        userType: [
          {
            id: 1,
            label: 'بازیکن',
            value: false,
          },
          {
            id: 2,
            label: 'مربی',
            value: false
          },
          {
            id: 3,
            label: 'داور',
            value: false
          },
        ],
        birthCity: {
          id: '',
          label: ''
        },
        city: {
          id: '',
          label: ''
        },
        character: [],
        role: {}
      },
      roles: []
    }
  },
  created() {
    // this.getProvinces()
    this.getUser()
    this.getAccessGroups()
  },
  methods: {
    getUser() {
      getUser(this.userId || this.$route.params.id).then(response => {
        const user = response.data.data

        this.user.name.value = user.name || ''
        this.user.lastName.value = user.family || ''
        this.user.company.value = user.company || ''
        this.user.phoneNumber.value = user.phone_number || ''
        this.user.email.value = user.email || ''
        this.user.role = {
          label: user.role ? user.role.name : 'مشتری',
          value: user.role ? user.role.id : 0
        }
      }).catch(() => {
      })
    },
    getProvinces() {
      getProvinces().then(response => {
        const provinces = response.data.data

        this.provinces = provinces.map(province => {
          this.cities[province.name] = province.cities.map(city => {
            return {
              id: city.id,
              label: city.name,
              value: city.name
            }
          })

          return {
            id: province.id,
            label: province.name,
            value: province.name
          }
        })
      })
    },
    getAccessGroups() {
      getRoles().then(response => {
        this.roles = this.getRoles(response.data.data)
      })
    },
    getRoles(role) {
      let roles = [
        {
          label: role.name,
          value: role.id
        }
      ]

      if (role.children && role.children.length > 0) {
        role.children.forEach((child) => {
          roles = [...roles, ...this.getRoles(child)]
        })
      }
      return roles
    },
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    searchPhoneNumber () {
      if (this.user.phoneNumber.isValid) {
        this.$vs.loading()
        getUsers(1, [`phone_number=${this.user.phoneNumber.value}`]).then(response => {
          if (response.data.data.length > 0) {
            const user = response.data.data[0]
            this.user.id = user.id
            this.user.name.value = user.name || ''
            this.user.lastName.value = user.family || ''
            this.user.phoneNumber.value = user.phone_number
            this.user.image.value = user.avatar || ''
            this.user.gender.value = user.gender || -1

            this.status = 'edit'
            this.inputsDisabled = false
          } else {
            this.$vs.notify({
              title: 'خطا',
              text: 'کاربری با این شماره پیدا نشد برای ثبت نام اطلاعات زیر را تکمیل کنید',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'danger'
            })
            this.status = 'insert'
            this.inputsDisabled = false
          }
          this.$vs.loading.close()
        })
          .catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: 'هشدار',
                text: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
            this.$vs.loading.close()
          })
      }
    },
    sendData() {
      if ( this.user.gender.value === -1 ||
          !this.user.name.isValid ||
          !this.user.lastName.isValid ||
          !this.user.phoneNumber.isValid) {

        this.$vs.notify({
          title: 'خطا',
          text: 'اطلاعات وارد شده صحیح نمی باشد',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400,
          color: 'danger'
        })
        return false
      }

      let character = []
      const userType = this.user.userType
      for (let i = 0; i < userType.length; i++) {
        if (userType[i].label === 'بازیکن' && userType[i].value) {
          character.push({character: 1})
        } else if (userType[i].label === 'مربی' && userType[i].value) {
          character.push({character: 2})
        } else if (userType[i].label === 'داور' && userType[i].value) {
          character.push({character: 3})
        }
      }

      this.user.character = character

      const user = {
        name: this.user.name.value,
        family: this.user.lastName.value,
        // father_name: this.user.fatherName.value,
        // national_code: this.user.nationalCode.value,
        // born_at: this.user.birthDate ? this.user.birthDate.value.toString().replaceAll('/', '-') + " 00:00:00" : null,
        // avatar: this.user.image ? this.user.image.value : '',
        gender: this.user.gender.value,
        // province_id: parseInt(this.user.province.id),
        // city_id: parseInt(this.user.city.id),
        // birth_province_id: parseInt(this.user.birthProvince.id),
        // birth_city_id: parseInt(this.user.birthCity.id),
        // birth_certificate_id: this.user.birthCertificateId.value,
        // insurance_status: this.user.sportInsurance.id === 1 ? 1 : 0,
        phone_number: this.user.phoneNumber.value,
        // email: this.user.email.value,
        // score: this.user.playerScore.value && this.user.userType[0].value ? this.user.playerScore.value : '',
        // coach_degree: this.user.coachDegree.id && this.user.userType[1].value ? this.user.coachDegree.id : '',
        // address: Object.keys(this.user.address).length > 0 && this.user.address.value ? this.user.address.value : '',
        company: '',
        max_debt: 1000000,
        role_id: 2,
        // characters: character
      }

      if (this.user.role && this.user.role.value > 0 && this.userId !== this.$store.state.auth.user.id) user.role_id = this.user.role.value

      if (this.status === 'insert') {
        insertUser(user).then(() => {
          this.$vs.notify({
            title: 'پیغام',
            text: 'اطلاعات مدیر با موفقیت ثبت شد',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('inserted')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: 'هشدار',
              text: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'name': 'نام انتخاب شده معتبر نیست!',
              'family': 'نام خانوادگی انتخاب شده معتبر نیست!',
              'national_code': 'کد ملی وارد شده معتبر نیست!',
              'born_at': 'تاریخ تولد انتخاب شده معتبر نیست!',
              'gender': 'جنسیت انتخاب شده معتبر نیست!',
              'phone_number': 'شماره تلفن وارد شده معتبر نیست!',
              'email': 'ایمیل وارد شده معتبر نیست!',
              'characters': 'نوع کاربری انتخاب شده معتبر نیست!'
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: 'خطا',
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: 'خطا',
                  text: 'ثبت اطلاعات مدیر با خطا مواجه شد',
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      } else {
        editUser(this.user.id || this.$route.params.id, user).then(() => {
          this.$vs.notify({
            title: 'پیغام',
            text: 'اطلاعات مدیر با موفقیت بروزرسانی شد',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })

          this.$emit('inserted')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: 'هشدار',
              text: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {

            const error_mapper = {
              'name': 'نام انتخاب شده معتبر نیست!',
              'family': 'نام خانوادگی انتخاب شده معتبر نیست!',
              'national_code': 'کد ملی وارد شده معتبر نیست!',
              'born_at': 'تاریخ تولد انتخاب شده معتبر نیست!',
              'gender': 'جنسیت انتخاب شده معتبر نیست!',
              'phone_number': 'شماره تلفن وارد شده معتبر نیست!',
              'email': 'ایمیل وارد شده معتبر نیست!',
              'characters': 'نوع کاربری انتخاب شده معتبر نیست!'
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: 'خطا',
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: 'خطا',
                  text: 'ویرایش اطلاعات مدیر با خطا مواجه شد',
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }

    },
    refreshCity(type) {
      switch (type) {
        case 'birth':
          this.user.birthCity = {
            id: '',
            label: '-',
            value: '-'
          }
          break
        default:
          this.user.city = {
            id: '',
            label: '-',
            value: '-'
          }
          break
      }
    },
    isValidIranianNationalCode(input) {
      if (!this.$validator('regex.setting.employee.nationalCode').test(input)) return false
      const check = +input[9]
      const sum = input.split('').slice(0, 9).reduce((acc, x, i) => acc + +x * (10 - i), 0) % 11
      return sum < 2 ? check === sum : check + sum === 11
    }
  },
  watch: {
    'user.nationalCode.value': {
      handler(val) {
        this.user.nationalCode.isValid = this.isValidIranianNationalCode(val)
      }
    }
  }
}
</script>

<style lang="scss">
.useral-edit-user {
  $divider: #cdcdcd;

  width: 100%;
  height: calc(100% - 10px);
  display: flex;

  @media (max-width: 767px) {
    display: block;
  }

  .menu-side {
    width: 200px;
    min-width: 200px;
    height: 100%;
    padding: 0 10px 0 0;

    @media (max-width: 767px) {
      width: 100%;
      min-width: 100%;
      padding: 0;
      height: fit-content;
    }

    ul {

      li {
        position: relative;
        border: 1px solid #cecece;
        border-radius: .5rem;
        margin-bottom: 10px;
        padding: 0 10px;
        line-height: 40px;
        cursor: pointer;
        transition: all 0.3s ease;

        .icon-chevron-left {
          position: absolute;
          height: fit-content;
          top: 0;
          bottom: 0;
          right: 6px;
          margin: auto;
        }

        &:hover {
          transform: translateX(5px);
        }
      }
    }
  }

  .view-side {
    flex-grow: 1;
    height: 100%;
    border: 1px solid #cecece;
    padding: 5px;
    border-radius: .5rem;
    overflow-y: auto;


    @media (max-width: 767px) {
      width: 100%;
      min-width: 100%;
      height: calc(100% - 230px);
    }

    .view-box {
      width: 100%;
      height: 100%;
      padding: 5px;
      overflow: auto;

      .custom-profile-image-input {
        .custom-profile-image-input {
          height: 110px;
          width: 110px;
        }
      }

      .phone-input {
        display: flex;
        align-items: center;

        .custom-phone-number-input {
          flex-grow: 1;
        }

        .confirm-phone {
          line-height: 35px;
          padding: 0 10px;
          margin-left: 5px;
          background-color: inherit;
          border: 1px solid $divider;
          border-radius: 0.5rem;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .date-picker-item {
        border: 0 !important;
        border-radius: 0.4rem;
        position: relative;

        .date-picker-label {
          font-size: 12px;
          position: absolute;
          top: -10px;
          left: 5px;
          padding: 0 5px;
          background-color: #ffffff;
          z-index: 1;
        }

        .vpd-input-group {
          width: 20px !important;
          position: absolute;
          top: 6px;
          left: 2px;

          .vpd-icon-btn {
            opacity: 1 !important;
            height: 25px !important;
            width: 20px !important;
            background-color: inherit !important;

            svg {
              fill: #000000;
              overflow: visible !important;
            }
          }

          .form-control {
            border: 0;
            width: 100%;
            display: none;
            line-height: 35px;
          }
        }
      }

      .custom-input:last-child {
        margin-bottom: 0;
      }

      .input-field-item {
        position: relative;
        border: 1px solid #cdcdcd;
        border-radius: 0.4rem;
        padding: 5px 10px;
        margin: 15px 0;
        display: flex;
        justify-content: space-around;
        min-height: 35px;

        &.invalid {
          border-color: #b00000;

          .input-field-label {
            color: #b00000;
          }
        }

        .input-field-label {
          position: absolute;
          font-size: 12px;
          top: -10px;
          left: 8px;
          background-color: #ffffff;
          padding: 0 5px;
        }

        .radio-item {
          display: flex;
          align-items: center;
          direction: rtl;

          label {
            margin-left: 5px;
          }
        }
      }

      div.edit-user-field {
        position: relative;

        span.label {
          font-size: 12px;
          position: absolute;
          top: -10px;
          left: 7px;
          z-index: 10000;
          background: #ffffff;
          padding: 0 5px;
        }

        .useral-custom-element-select {
          margin: 15px 0;

          .selected {
            height: 35px;
            line-height: 35px;
            font-size: 13px;
          }

          .items {
            line-height: 35px;

            div {
              height: 35px;
              font-size: 13px;
            }
          }
        }
      }

      .error-alert {
        display: inline-block;
        border: 1px solid #ffba00;
        border-radius: .5rem;
        color: #ffba00;
        padding: 0 10px;
        line-height: 50px;
        height: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;

        i {
          font-size: 15px;
        }
      }

      &::-webkit-scrollbar {
        display: block;
        border-radius: .2rem;
        width: 10px;
        background: #f9f9fd;
        right: 10px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: .2rem;
        background: #cecece;
      }
    }
  }
}
</style>
